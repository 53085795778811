import { Box, Button, Grid, Grommet, Heading, CheckBox, Text } from 'grommet';
import { StatusGood, StatusWarning } from 'grommet-icons';
import React, {useState} from 'react';

import theme from '../../theme';
import * as colorUtils from '../../utils/color';

const Colors = (props) => {
  const [checked, setChecked] = useState(false);
  
  return (
    <Grommet full theme={theme} themeMode={checked ? "dark" : "light"} background="background-back">
      <Box pad={{ horizontal: 'none', vertical: 'small' }} width={{ min: 'medium', max: 'large' }} margin="auto" direction="row" justify="between">
        <Heading level="1" style={{ fontWeight: 200 }}>
          Colors
        </Heading>
        <CheckBox
          {...props}
          label="Dark mode"
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          toggle
        />
      </Box>
      <Box width={{ min: 'medium', max: 'large' }} margin="auto" background="background-front" pad="large">
        <Grid
          columns={{
            count: 4,
            size: 'auto',
          }}
          gap="medium"
        >
          {Object.keys(theme.global.colors).map((color) => {
            if (!theme.global.colors[color]) return;
            const normalizedColor = colorUtils.normalizeColor(color, theme, checked);
            const textColor = colorUtils.colorIsDark(normalizedColor) == true ? 'white' : 'text';
            const colorRatioBlk = 1 / colorUtils.ratio('#000000', normalizedColor);
            const colorRatioWht = 1 / colorUtils.ratio('#FFFFFF', normalizedColor);
            return (
              <Box key={color} direction="column" background={normalizedColor} pad={{ horizontal: 'small', top: 'small', bottom: 'large' }}>
                <Text size="xsmall" weight="bold" color={textColor}>
                  {color}
                </Text>
                <Text size="xsmall" color={textColor}>
                  {normalizedColor}
                </Text>
                <Text color="#FFF">
                  {normalizedColor && Math.round(colorRatioWht)} : 1{' '}
                  {colorRatioWht > 4.5 ? <StatusGood color="green!" size="small" /> : <StatusWarning color="status-warning" size="small" />}
                </Text>
                <Text color="#000">
                  {normalizedColor && Math.round(colorRatioBlk)} : 1{' '}
                  {colorRatioBlk > 4.5 ? <StatusGood color="green!" size="small" /> : <StatusWarning color="status-warning" size="small" />}
                </Text>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Grommet>
  );
};

export default Colors;
